body {
   background: #dcdddf;
   color: #000;
   font: 14px Arial;
   margin: 0 auto;
   padding: 0;
   position: relative;
}

.form-white.input-group > .form-control:focus {
   border-color: #fff;
   box-shadow: inset 0 0 0 1px #fff;
}

.container {
   margin: 25px auto;
   position: relative;
   width: auto;
}
#content {
   background: #f9f9f9;
   background: linear-gradient(
      top,
      rgba(248, 248, 248, 1) 0%,
      rgba(249, 249, 249, 1) 100%
   );
   box-shadow: 0 1px 0 #fff inset;
   border: 1px solid #c4c6ca;
   margin: 0 auto;
   padding: 25px 0 0;
   position: relative;
   text-align: center;
   text-shadow: 0 1px 0 #fff;
   width: 400px;
}
#content h1 {
   color: #7e7e7e;
   font: bold 25px Helvetica, Arial, sans-serif;
   letter-spacing: -0.05em;
   line-height: 20px;
   margin: 10px 0 30px;
}
#content h1:before,
#content h1:after {
   content: "";
   height: 1px;
   position: absolute;
   top: 10px;
   width: 27%;
}
#content h1:after {
   background: rgb(126, 126, 126);
   background: linear-gradient(
      left,
      rgba(126, 126, 126, 1) 0%,
      rgba(255, 255, 255, 1) 100%
   );
   right: 0;
}
#content h1:before {
   background: rgb(126, 126, 126);
   background: linear-gradient(
      right,
      rgba(126, 126, 126, 1) 0%,
      rgba(255, 255, 255, 1) 100%
   );
   left: 0;
}
#content:after,
#content:before {
   background: #f9f9f9;

   background: linear-gradient(
      top,
      rgba(248, 248, 248, 1) 0%,
      rgba(249, 249, 249, 1) 100%
   );
   border: 1px solid #c4c6ca;
   content: "";
   display: block;
   height: 100%;
   left: -1px;
   position: absolute;
   width: 100%;
}
#content:after {
   transform: rotate(2deg);
   top: 0;
   z-index: -1;
}
#content:before {
   transform: rotate(-3deg);
   top: 0;
   z-index: -2;
}
#content form {
   margin: 0 20px;
   position: relative;
}
#content form select,
#content form input[type="text"],
#content form input[type="email"],
#content form textarea {
   border-radius: 3px;
   box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
   transition: all 0.5s ease;
   background: #eae7e7 no-repeat;
   border: 1px solid #c8c8c8;
   color: #777;
   font: 13px Helvetica, Arial, sans-serif;
   margin: 0 0 10px;
   padding: 15px 10px 15px 40px;
   width: 80%;
}
#content form select:focus,
#content form input[type="text"]:focus,
#content form input[type="email"]:focus,
#content form textarea:focus {
   background-color: #fff;
   outline: none;
}

#content form select::-webkit-input-placeholder,
#content form input[type="text"]::-webkit-input-placeholder,
#content form input[type="email"]::-webkit-input-placeholder,
#content form textarea::-webkit-input-placeholder {
   font-size: large;
}

::-webkit-input-placeholder #content form div a {
   color: #004a80;
   float: right;
   font-size: 12px;
   margin: 30px 15px 0 0;
   text-decoration: underline;
}
#msgEmailName {
   font-size: 80%;
   font-weight: 600;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -750%);
   cursor: pointer;
   user-select: none;
}
#msgFrom {
   font-size: 80%;
   font-weight: 600;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -300%);
   cursor: pointer;
   user-select: none;
}
#msgBody {
   font-size: 80%;
   font-weight: 600;
   position: absolute;
   top: 50%;
   transform: translate(-50%, 900%);
   cursor: pointer;
   user-select: none;
}

#form-textarea-control-opinion {
   display: block;
   margin: 2px auto !important;
}
