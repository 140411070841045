button.link-button {
   background-color: transparent;
   border: none;
   cursor: pointer;
   text-decoration: underline;
   display: inline;
   /* margin: 0;
  padding: 0; */
   text-decoration: none;
}

.container {
   width: auto;
   height: auto;
}

.link-button:hover,
.link-button:focus {
   text-decoration: none;
}

div.row div.col-md-4 img {
   height: 200px;
   width: 200px;
}

.mainCard {
   width: auto;
   margin: 5px;
   height: auto;
   border-radius: 5%;
}

.cardImageMain {
   height: 200px;
   width: 100%;
   border-top-left-radius: 5%;
   border-top-right-radius: 5%;
}

@keyframes App-logo-spin {
   fr\om {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.d-block.w-100 {
   max-width: 1500px;
   max-height: 500px;
   margin: auto auto;
   border-radius: 5%;
   margin-top: 5px !important;
}

.d-inline-block.align-top {
   border-radius: 50%;
}

.dayTourCard {
   border-radius: 3%;
   margin: 10px;
   width: 95%;
}

.dayTourCardButton {
   margin: 0% 20%;
   float: right;
}

.dripicons-cross:before {
   content: "\X";
   border-radius: 20%;
}

.tourDetails {
   font-size: large;
}

.large {
   font-size: large;
   margin-right: 10px;
   cursor: pointer;
}
.justify {
   justify-content: center;
   margin: 3% 25%;
}
